'use client';
import { useContext, useLayoutEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { Layout, theme } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AdminNav } from '@app/components/nav';
import { AuthContext } from '@app/context/auth';
import { AdminLayoutProps } from './AdminLayout.props';

const { Content } = Layout;
const isBrowser = typeof window !== 'undefined';

const AdminLayout = ({ children }: AdminLayoutProps) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const [auth, setAuth] = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  useLayoutEffect(() => {
    const authItem = localStorage.getItem('auth');
    if (authItem) {
      setAuth(JSON.parse(authItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // config axios
    if (!isBrowser) {
      axios.defaults.baseURL = process.env.API;
      axios.defaults.headers.common['Authorization'] = `Bearer ${auth?.token}`;
    } else {
      axios.defaults.baseURL = process.env.NEXT_PUBLIC_API;
      axios.defaults.headers.common['Authorization'] = `Bearer ${auth?.token}`;
    }
    if (auth?.token) {
      getCurrentAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.token]);

  const getCurrentAdmin = async () => {
    try {
      const { data } = await axios.get('/current-admin');
      // console.log(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      router.push('/');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <LoadingOutlined style={{ fontSize: '50px', color: '#3366ff' }} />
      </div>
    );
  }

  return (
    <Layout>
      <AdminNav />
      <Layout>
        <Content
          style={{
            color: colorPrimary,
            padding: '10px',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
