'use client';
import { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { Menu, Layout, theme } from 'antd';
import {
  PushpinOutlined,
  CameraOutlined,
  UserSwitchOutlined,
  SettingOutlined,
  BgColorsOutlined,
  UserOutlined,
  CommentOutlined,
} from '@ant-design/icons';
import { useWindowWidth } from '@react-hook/window-size';
import { AuthContext } from '@app/context/auth';

const { Sider } = Layout;
const isBrowser = typeof window !== 'undefined';

function getItem(label: any, key: any, icon?: any, children?: any) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const AdminNav = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState('');

  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();

  const onlyWidth = useWindowWidth();

  const activeName = (name: any) => `${current === name ? 'active' : ''}`;

  const items = [
    getItem(
      <Link href="/admin/" className={activeName('/admin/')}>
        Dashboard
      </Link>,
      '1',
      <SettingOutlined />,
    ),
    getItem('Posts', 'sub1', <PushpinOutlined />, [
      getItem(
        <Link href="/admin/posts/" className={activeName('/admin/posts/')}>
          All Posts
        </Link>,
        '2',
      ),
      getItem(
        <Link
          href="/admin/posts/new/"
          className={activeName('/admin/posts/new/')}
        >
          Add New
        </Link>,
        '3',
      ),
      getItem(
        <Link
          href="/admin/categories/"
          className={activeName('/admin/categories/')}
        >
          Categories
        </Link>,
        '4',
      ),
    ]),
    getItem('Media', 'sub2', <CameraOutlined />, [
      getItem(
        <Link
          href="/admin/media/library/"
          className={activeName('/admin/media/library/')}
        >
          Library
        </Link>,
        '5',
      ),
      getItem(
        <Link
          href="/admin/media/new/"
          className={activeName('/admin/media/new/')}
        >
          Add New
        </Link>,
        '6',
      ),
    ]),
    getItem(
      <Link href="/admin/comments/" className={activeName('/admin/comments/')}>
        Comments
      </Link>,
      '7',
      <CommentOutlined />,
    ),
    getItem('Users', 'sub3', <UserSwitchOutlined />, [
      getItem(
        <Link href="/admin/users/" className={activeName('/admin/users/')}>
          All Users
        </Link>,
        '8',
      ),
      getItem(
        <Link
          href="/admin/users/new/"
          className={activeName('/admin/users/new/')}
        >
          Add New
        </Link>,
        '9',
      ),
    ]),
    getItem(
      <Link href="/admin/userid/" className={activeName('/admin/userid/')}>
        Profile
      </Link>,
      '10',
      <UserOutlined />,
    ),
    getItem(
      <Link
        href="/admin/customize/"
        className={activeName('/admin/customize/')}
      >
        Customize
      </Link>,
      '11',
      <BgColorsOutlined />,
    ),
  ];

  useEffect(() => {
    isBrowser && setCurrent(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrowser && window.location.pathname]);

  useEffect(() => {
    if (onlyWidth < 800) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [onlyWidth]);

  return (
    <Sider
      collapsed={collapsed}
      collapsible
      onCollapse={(value) => setCollapsed(value)}
      style={{
        width: 256,
        background: colorBgContainer,
        color: colorPrimary,
      }}
    >
      <Menu
        // defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1', 'sub2', 'sub3']}
        mode="inline"
        items={items}
      />
    </Sider>
  );
};
export default AdminNav;
